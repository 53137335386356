import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/pages/auth/shared/services/auth.service'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // Form login
  form: FormGroup;
  loading = false;
  submitted = false;

  // Auth process
  returnUrl: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-zA-Z]{2,4}$')]
      ],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  /**
  * @public
  * @name formControl
  * @description Getter for easy access to form fields
  */
  get formControl() { return this.form.controls; }

  /**
   * @public
   * @name onSubmit
   * @description Submit credential for login
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this._snackBar.open('Please check your input', '', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      })

      return;
    }

    this.loading = true;
    this.authService.login(this.formControl.email.value, this.formControl.password.value)
      .subscribe(
        data => {
          if (this.returnUrl !== '/') {
            const part = this.returnUrl.split("?")
            if (part[1]) {
              const queryParams = part[1].split("=")
              this.router.navigate([`${part[0]}`], {
                queryParams: {
                  conversationId: queryParams[1]
                }
              })
            } else {
              this.router.navigate([`${part[0]}`])
            }

          } else {
            this.router.navigate(['/dashboard']);
          }
        },
        error => {
          this._snackBar.open('Invalid email or password', '', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top'
          });
          this.loading = false;
        });
  }
}
